// start content

@media (min-width: 768px){
    .colis_details .col-sm-2, .colis_details_std .col-sm-2 {
        -ms-flex: 0 0 18%;
        flex: 0 0 18%;
        max-width: 18%;
    }
    .colis_details .col-sm-2:nth-child(3), .colis_details_std .col-sm-2:nth-child(3) {
        -ms-flex: 0 0 20% !important;
        flex: 0 0 20% !important;
        max-width: 20% !important;
    }
    .colis_details .col-sm-2:last-child, .colis_details_std .col-sm-2:last-child {
        -ms-flex: 0 0 8% !important;
        flex: 0 0 8% !important;
        max-width: 8% !important;
    }
}
@media (max-width: 1200px){
    nav.navbar-general ul li a, nav.navbar-general ul li a.btn-outline-primary{
        font-size: 12px !important;
    }
    li.nav-item.dropdown.dropdown-arrow.dropdown-currencies {
        margin-right: 0;
    }
    .accordion.countries-list .card-header .card-link {
        font-size: 15px;
    }
    section.after-transporteur-exclusif::before {
        content: "";
        height: 316px;
    }
    .img-sol-log-decal{
        top: 0;
        right: 0px;
        position: relative;
    }
    .offre-main-info .bloc .price-ht{
        font-size: 16px;
    }
    .miniform-upela-wrapper.collapse.show .miniform-upela {
        margin-bottom: 30px;
    }
    .total-to-pay .total-amount {
        font-size: 16px;
    }
    .widget-total img {
        max-width: 35px;
    }
    .radio-inline.facturation_type input ~ span {
        font-size: 12px;
    }
}
@media (max-width: 990px){
    .page-header .tunnel-confirmation h1 {
        margin: 40px 0 5px 0;
    }
    .widget-total img {
        max-width: none;
    }
    .total-to-pay .total-amount {
        font-size: 20px;
        text-align: right;
    }
    .offre-main-info:hover .bloc, .offre-main-info:hover .bloc.avec-assurance{
        border: 1px solid #169FEC;
    }
    #filtre-offres {
        position: fixed;
        top: 80px;
        bottom: 0;
        z-index: 999;
        left: 0;
        right: 0;
        padding: 20px;
        border-radius: 0;
        border: 0;
        border-top: 1px solid #C4C4C4;
        overflow-y:scroll;
        overflow-x:hidden;
    }
    #filtre-offres .card-header .card-link {
       padding: 0;
    }
    #filtre-offres button.close {
        position: absolute;
        top: 25px;
        right: 40px;
        font-size: 40px;
        color: #4c5366;
        opacity: 1;
    }
    #filtre-offres .card-body, #filtre-offres .card-body p {
        padding: 1.25rem 0;
    }
    #filtre-offres .card.last {
        border-bottom: 0;
        padding-bottom: 50px;
    }
    #filtre-offres .filter-btns {
        margin-left: 0;
        margin-right: 0;
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        right: 0;
    }
    #filtre-offres .filter-btns .btn{
        border-radius: 0;
        line-height: 40px;
    }
    #filtre-offres .filter-btns .col-6:first-child .btn{
        border-right: 1px solid #C4C4C4;
    }
    #filtre-offres .filter-btns>[class^="col-"]{
        padding-left: 0;
        padding-right: 0;
    }
    section.tunnel-body {
        padding: 30px 0 30px 0;
    }
    .offre-main-info img.offre-logo {
        display: inline-block;
        margin: auto;
        margin-right: 10px;
    }
    .offre-main-info .offre-col-1{
        border-right: 0;
    }
    .offre-item .bloc {
        border-bottom: 0;
        border-radius: 8px 8px 0 0;
    }
    .offre-item .bloc.avec-assurance{
        border-radius: 0 0 8px 0;
    }
    .offre-item .bloc.sans-assurance{
        border-radius: 0 0 0 8px;
    }
    .offre-main-info .bloc .price-ht {
        font-size: 24px;
    }
    .offre-item .row.offre-main-info {
        margin-right: 0;
        margin-left: 0;
    }
    .offre-item .row.offre-main-info > div[class^="col"] {
        padding-right: 0;
        padding-left: 0;
    }
    .offre-main-info .offre-title, body .offre-main-info .offre-subtitle {
        width: auto;
    }
    #offreModal .modal-content .upela-form{
        padding: 0px 0px;
    }
    .te-auto{
        height: auto;
    }
    .gradient-bg-transporteurs .te-body {
        height: 200px;
    }
    .te-box.te-info .te-head {
        font-size: 18px;
    }
    .te-box .content-heading {
        height: auto;
    }
    .te-body.firstcol .readmore {
        background: #f5f5f5;
    }
    section.after-transporteur-exclusif::before {
        content: "";
        height: 301px;
    }
    .te-body.firstcol {
        height: auto;
        background-color: #ffffff;
    }
    .miniform-upela {
        padding: 0 15px 20px;
        position: relative;
        top: 30px;
    }
    .page-header h1 {
        font-size: 32px;
        margin: 40px 0 40px 0;
    }
    section.knowledge-base .alert-info {
        line-height: 25px;
    }
    nav.navbar-general ul li a, nav.navbar-general ul li a.btn-outline-primary {
        font-size: 16px !important;
    }
    nav.navbar-general ul li a.btn-outline-primary {
        margin: 15px 10px;
    }
    .navbar-expand-md .navbar-toggler {
        display: block;
    }
    .collapse:not(.show) {
        display: none !important;
    }
    .navbar-expand-md>.container, .navbar-expand-md>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
    /*.navbar-header {
        float: none;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin: 7.5px -15px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }*/
    .navbar-toggler {
        font-size: 24px;
        position: absolute;
        top: 22px;
    }
    .navbar-toggler.navbar-toggler-bars {
        right: 15px;
    }
    .navbar-toggler.navbar-toggler-user {
        right: 55px;
    }
    nav.navbar-general ul.navbar-nav.ml-auto li a {
        font-size: 16px;
    }
    .navbar-expand-md .navbar-collapse {
        display: -ms-inline-block!important;
        display: inline-block;
        -ms-flex-preferred-size: unset;
        flex-basis: unset;
    }
    div#navbarCollapseRight, div#navbarCollapseLeft {
        background: rgb(255, 255, 255);
        width: 100%;
        padding: 0;
        margin: 0;
        position: absolute;
        top: 80px;
        right: 0;
        border-top: 1px solid #e8e7e7;
    }
    .navbar-expand-md .navbar-nav {
        -ms-flex-direction: unset;
        flex-direction: unset;
        width: 100%;
        display: inline-block;
    }
    .navbar-nav li.nav-item {
        width: 100%;
        display: inline-block !important;
        float: left;
        border-bottom: 1px solid #e8e7e7;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: relative;
    }
    nav.navbar-general ul.navbar-nav.mr-auto:before, nav.navbar-general .dropdown-menu:before{
        display: none;
    }
    li.nav-item.dropdown .dropdown-toggle::after {
        display: inline-block;
        font-family: "fontawesome";
        content: "\f078";
        border: 0;
        font-size: 12px;
        color: #C4C4C4;
        float: right;
    }
    .navbar-expand-md .navbar-nav .nav-link {
        padding: 10px 1rem;
    }
    .dropdown:hover .dropdown-menu {
        display: inline-block;
        width: 100%;
    }
    nav.navbar-general .dropdown-menu {
        background: #F0F0F0;
        left: 0;
        transform: none;
        border-radius: 0px;
    }
    .navbar-nav .dropdown-menu {
        position: relative;
        float: none;
    }
    nav.navbar-general ul li a{
        color: #42505D;
    }
    .upela-form .col-destination::after {
        display: none;
    }
    .upela-form {
        background: transparent;
        border-radius: 0;
        padding: 0;
        margin-bottom: 40px;
    }
    .upela-form .col-destination .destinationbox, .upela-form .col-envoi .envoibox{
        background: #FFFFFF;
        margin-bottom: 15px;
        border-radius: 15px;
        padding: 20px 20px 10px 20px;
    }
    .upela-form-title {
        margin-bottom: 20px;
    }
    .upela-form label.input-label {
        text-indent: 0px;
    }
    .upela-form .row-destination-envoi {
        margin-right: -15px;
        margin-left: -15px;
    }
    .upela-form .col-destination, .upela-form .col-envoi {
        padding-right: 15px;
        padding-left: 15px;
    }
    i#exchange {
        margin: 15px auto 25px;
    }
    .sendcolis {
        position: relative;
    }
    table a.action-link {
        margin-right: 10px;
    }
}
@media (max-width: 767px){
    .upela-form-style input.inline-input {
        margin: 10px 10px;
        display: block;
    }
    .page-header .tunnel-confirmation h1 {
        font-size: 22px;
    }
    .btn-print .btn-title {
        font-size: 18px;
        margin-bottom: 9px;
    }
    .radio-inline.facturation_type input ~ span {
        line-height: 17px;
        padding: 10px;
    }
    .offres-grid .radio-inline.offre_type .title {
        font-size: 14px;
        line-height: 12px;
        margin-top: 20px;
    }
    .offres-grid .radio-inline.offre_type .offre-spec, .offres-grid .radio-inline.offre_type .offre-spec span sup {
        font-size: 10px;
    }
    .tunnel-schema .current .schema-label.d-none{
        display: block !important;
    }
    .tunnel-schema .schema::before,.tunnel-schema .schema::after{
        top: calc((50% + 13px));
    }
    .tunnel-schema .schema-dot{
        top: 18px;
    }
    .tunnel-schema .active.current .schema-dot:before {
        top: -27px;
    }
    .rounded-box {
        padding: 40px 40px;
    }
    .form-style-dark {
        margin: 0 -40px;
        padding: 30px 40px;
    }
    .form-group-box {
        background: #FFFFFF;
        border: 1px solid #C4C4C4;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 30px 25px 20px;
    }
    table a.action-link {
        margin-right: 0;
        margin-bottom: 10px;
        display: inline-block;
        width: 100%;
    }
    table a.action-link:last-child {
        margin-bottom: 0px;
    }
    .gradient-bg-transporteurs .te-body {
        height: 143px;
    }
    section.after-transporteur-exclusif::before {
        content: "";
        height: 245px;
    }
    .error-header h1 {
        padding: 90px 0;
        font-size: 30px;
        line-height: 12px;
    }
    .error-header h1 span {
        font-size: 120px;
    }
    .section-title {
        font-size: 20px;
        line-height: 28px;
    }
    .section-title span {
        font-size: 14px;
        line-height: 28px;
    }
    section.error-body, section.success-body {
        padding: 60px 0;
    }
    .page-header h1 {
        font-size: 24px;
        line-height: 34px;
        margin: 40px 0 40px 0;
    }
    .page-header .has-search .form-control {
        padding-left: 40px;
        font-size: 12px;
    }
    .has-search .form-control-feedback {
        width: 40px;
    }
    .upela-form {
        padding: 0px;
        margin-bottom: 40px;
    }
    .colis_details label, .colis_details_std label{
        display: inline-block;
        height: 15px;
        padding-top: 7px;
    }
    a.remove_colis, a.remove_colis_std {
        position: relative;
        top: 24px;
        left: 10px;
    }
    .upela-steps {
        margin-bottom: 0px;
        padding: 30px;
    }
    .upela-steps .number {
        top: -14px;
        left: 15px;
    }
    .headerhome h1 {
        font-size: 24px;
        line-height: 28px;
    }
    h2 {
        font-size: 24px;
        line-height: 32px;
    }
    h1 br, h3 br, h2 br{
        display: none;
    }
    .upela-form .col-destination, .upela-form .col-envoi {
        padding-right: 15px;
        padding-left: 15px;
    }
    .upela-form .row-destination-envoi {
        margin-right: -15px;
        margin-left: -15px;
    }
    .upela-form-title {
        margin-bottom: 25px;
        margin-top: 10px;
    }
    .footer-menu{
        display: none;
    }
    .footer-menu-title{
        height: 50px;
    }
    footer .footer-menu {
        margin-top: 10px;
    }
}
@media (max-width: 374px){
    .offres-grid .radio-inline.offre_type .title {
        font-size: 11px;
    }
    .offres-grid .radio-inline.offre_type .offre-spec, .offres-grid .radio-inline.offre_type .offre-spec span sup {
        font-size: 8px;
        line-height: 10px;
        margin-top: 5px;
    }
    .radio-inline.facturation_type input ~ span {
        line-height: 14px;
        padding: 10px 5px;
        font-size: 10px;
    }
    .btn-print .btn-title {
        font-size: 14px;
        line-height: 20px;
    }
    .btn-print .btn-subtitle {
        font-size: 12px;
        line-height: 16px;
    }
    .page-header .tunnel-confirmation h1 {
        font-size: 16px;
    }
    .page-header .tunnel-confirmation .subtitle {
        font-size: 14px;
    }
}